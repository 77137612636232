<template>
  <div class="p_m row ml-0">
    <button
      class="minus"
      aria-label="Уменьшить количество товара"
      @click="removeProductUpdateLine({productId, sizeId, quantity, completely: quantity === 1})"
    >
      <img src="@/assets/images/minus.svg" alt="minus">
    </button>
    <div class="number">
      <p class="ten">
        <span :class="{'not-available-item': itemNotAvailable}">{{ quantity }}</span>
      </p>
    </div>
    <button
      aria-label="Уменьшить количество товара"
      class="plus"
      @click="addProductUpdateLine({productId, sizeId})"
    >
      <img src="@/assets/images/plus.svg" alt="plus">
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {showConfirm} from "@/lib/swal2";

export default {
  name: "CartLineProductQuantity",
  props: {
    productId: {
      type: String
    },
    sizeId: {
      type: Number
    },
    quantity: {
      type: Number
    },
    itemNotAvailable: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {},
  methods: {
    ...mapActions('cart/dealer', ['addProductUpdateLine', 'removeProductUpdateLine'])
  }
}
</script>

<style scoped>
.minus, .plus {
  width: 25px;
  height: 25px;
  padding: 4px 0;
  outline: none;
  border: none;
  background-color: inherit;
}

.number {
  margin: 0 5px;
}

.ten {
  margin-top: 6px;
}

.p_m {
  margin-top: 10px;
}

.not-available-item{
  color: red;
}

@media (max-width: 767px) {
  .minus, .number, .plus {
    float: left;
    display: inline-block;
    padding: 0;
  }
}
</style>
