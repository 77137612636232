<template>
  <tr v-if="line">
    <td scope="row">
      <div class="vertical-imgs">
        <img alt="" class="item" :src="getProductImageSrc(line.productImageId)">
      </div>
    </td>
    <td>
      <span :class="{'not-available-item': itemNotAvailable}">{{line.productName}} <span v-if="line.quantityInBox > 1">({{line.quantityInBox}} шт.)</span> <span v-if="line.sizeName" class="size-name">{{line.sizeName}}</span></span>
    </td>
    <td>
      <span :class="{'not-available-item': itemNotAvailable}"> {{line.price | fractionSizeSpace}} {{makeDeclension(line.price, "балл", "балла", "баллов")}}</span>
    </td>
    <td>
      <product-quantity :style="itemNotAvailable ? 'visibility: hidden;' : 'visibility: visible;'" :product-id="line.productId" :size-id="line.sizeId" :quantity="line.quantity" :item-not-available="itemNotAvailable"  />
    </td>
    <td>
      <span v-if="line.price > 0" :class="{'not-available-item': itemNotAvailable}">
        {{line.price * line.quantity | fractionSizeSpace}} {{makeDeclension(line.price * line.quantity, "балл", "балла", "баллов")}}
      </span>
      <span v-if="line.price == 0" :class="{'not-available-item': itemNotAvailable}"> - </span>
    </td>
    <td class="text-center">
      <button
        :class="{
          'not-available-item-close': itemNotAvailable
        }"
        :disabled="isCartUpdating"
        class="close-oval"
        @click="removeProductUpdateLine({productId: line.productId, sizeId: line.sizeId, completely: true})"
      >
        <span class="close">&times;</span>
      </button>
      <span v-if="itemNotAvailable" class="d-block not-available-item">Временно отсутствует</span>
    </td>
  </tr>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CartLineProductQuantity from "./CartLineProductQuantity";

export default {
  name: "CartLine",
  components: {
    'product-quantity': CartLineProductQuantity
  },
  props: {
    line: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('common', ['getProductImageSrc', 'makeDeclension']),
    ...mapGetters('cart/dealer', ['isCartUpdating']),
    itemNotAvailable() {
      return !!(!this.line.productIsAvailable || this.line.productIsHidden);
    }
  },
  methods: {
    ...mapActions('cart/dealer', ['removeProductUpdateLine']),
  }
}
</script>

<style scoped>
h3 {
  padding-top: 30px;
}

.item {
  width: 100%;
  height: 100%;
  /*margin-top: 16px;*/
}

.table thead th {
  border: none !important;
}

th {
  font-weight: 700;
  font-size: 12px;
}

.table td, .table th {
  border: none;
  border-bottom: 1px solid rgba(179, 179, 179, 0.35);
}

.table td {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
}


.close-oval {
  width: 26px;
  height: 26px;
  background-color: #4a4a4a;
  border: none;
  border-radius: 50%;
  margin-top: 6px;
  cursor: pointer;
}

.close {
  font-size: 20px;
  font-weight: normal;
  font-family: Serif;
  color: white;
  position: relative;
  opacity: 1;
  top: -1px;
  text-align: center;
  left: -1px;
}

td {
  font-weight: 600;
  font-size: 15px;
  vertical-align: middle;
  font-size: 16px;
}

.vertical-imgs {
  width: 60px;
  height: 77px;
  /*padding-bottom: 25px;*/
}

.not-available-item{
  color: red;
}

.not-available-item-close {
  background-color: red;
}

</style>
